// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-alushta-js": () => import("./../../../src/pages/alushta.js" /* webpackChunkName: "component---src-pages-alushta-js" */),
  "component---src-pages-anonses-js": () => import("./../../../src/pages/anonses.js" /* webpackChunkName: "component---src-pages-anonses-js" */),
  "component---src-pages-catalog-js": () => import("./../../../src/pages/catalog.js" /* webpackChunkName: "component---src-pages-catalog-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-contest-js": () => import("./../../../src/pages/contest.js" /* webpackChunkName: "component---src-pages-contest-js" */),
  "component---src-pages-educate-js": () => import("./../../../src/pages/educate.js" /* webpackChunkName: "component---src-pages-educate-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interactive-plan-js": () => import("./../../../src/pages/interactive-plan.js" /* webpackChunkName: "component---src-pages-interactive-plan-js" */),
  "component---src-pages-memory-js": () => import("./../../../src/pages/memory.js" /* webpackChunkName: "component---src-pages-memory-js" */),
  "component---src-pages-methodical-js": () => import("./../../../src/pages/methodical.js" /* webpackChunkName: "component---src-pages-methodical-js" */),
  "component---src-pages-methodical-memory-js": () => import("./../../../src/pages/methodical-memory.js" /* webpackChunkName: "component---src-pages-methodical-memory-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-place-of-memory-js": () => import("./../../../src/pages/place-of-memory.js" /* webpackChunkName: "component---src-pages-place-of-memory-js" */),
  "component---src-pages-practical-seminar-js": () => import("./../../../src/pages/practical-seminar.js" /* webpackChunkName: "component---src-pages-practical-seminar-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-project-1-js": () => import("./../../../src/pages/project-1.js" /* webpackChunkName: "component---src-pages-project-1-js" */),
  "component---src-pages-simf-js": () => import("./../../../src/pages/simf.js" /* webpackChunkName: "component---src-pages-simf-js" */),
  "component---src-pages-training-events-js": () => import("./../../../src/pages/training-events.js" /* webpackChunkName: "component---src-pages-training-events-js" */),
  "component---src-pages-volunteer-js": () => import("./../../../src/pages/volunteer.js" /* webpackChunkName: "component---src-pages-volunteer-js" */),
  "component---src-pages-volunteer-manual-js": () => import("./../../../src/pages/volunteer-manual.js" /* webpackChunkName: "component---src-pages-volunteer-manual-js" */),
  "component---src-pages-volunteer-news-js": () => import("./../../../src/pages/volunteer-news.js" /* webpackChunkName: "component---src-pages-volunteer-news-js" */),
  "component---src-pages-war-js": () => import("./../../../src/pages/war.js" /* webpackChunkName: "component---src-pages-war-js" */),
  "component---src-templates-alushta-post-js": () => import("./../../../src/templates/alushta-post.js" /* webpackChunkName: "component---src-templates-alushta-post-js" */),
  "component---src-templates-catalogue-post-js": () => import("./../../../src/templates/catalogue-post.js" /* webpackChunkName: "component---src-templates-catalogue-post-js" */),
  "component---src-templates-event-post-js": () => import("./../../../src/templates/event-post.js" /* webpackChunkName: "component---src-templates-event-post-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-place-of-memory-post-js": () => import("./../../../src/templates/place-of-memory-post.js" /* webpackChunkName: "component---src-templates-place-of-memory-post-js" */),
  "component---src-templates-simf-post-js": () => import("./../../../src/templates/simf-post.js" /* webpackChunkName: "component---src-templates-simf-post-js" */),
  "component---src-templates-war-post-js": () => import("./../../../src/templates/war-post.js" /* webpackChunkName: "component---src-templates-war-post-js" */)
}

